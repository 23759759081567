import { extendTheme } from '@chakra-ui/react'
import "@fontsource/eb-garamond"

const theme = extendTheme({
  fonts: {
    // heading: `'EB Garamond', serif`,
    body: "Gilmer"      
  },
})

export default theme;